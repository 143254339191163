import request from './request.js';

export const netNameStatistics = (params) => {
  return request({
    url: '/api/blade-admin/statistics/h5/netNameStatistics',
    method: 'get',
    params
  })
}

export const vmcInfoStatistics = (params) => {
  return request({
    url: '/api/blade-admin/statistics/h5/vmcInfoStatistics',
    method: 'get',
    params
  })
}

export const vmcInfos = (params) => {
  return request({
    url: '/api/blade-admin/vmcinfo/cargoWay',
    method: 'get',
    params
  })
}