<template>
  <div class="warp">
    <van-search
        v-model="netName"
        show-action
        placeholder="搜索网点名称"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-dropdown-menu>
      <van-dropdown-item v-model="district" :options="areaList" @change="getInitData"/>
      <van-dropdown-item v-model="sort" :options="sortList" @change="getInitData"/>
    </van-dropdown-menu>
    <div v-for="(item, index) in tableList" :key="index" class="item" @click="toDetail(item)">
      <div class="title">{{ item.netName }}</div>
      <div class="infos">
        <span class="span">货架：{{ item.vmcCount }}个</span>
        <span class="span">总格口：<span class="_F4730C">{{ item.totalCount }}</span>个</span>
        <span class="span">库存：{{ item.stocks }}个</span>
        <span class="span">缺货：<span class="_F4730C">{{ item.outStocks }}</span>个</span>
      </div>
      <van-icon name="arrow" class="arrow" />
    </div>
  </div>
</template>

<script>
import { netNameStatistics } from '../../assets/api/netPoint.js'
import {getAreaList} from "../../assets/api";
export default {
  data() {
    return {
      netName: '',
      district: '',
      areaList: [{ text: '全部区域', value: '' }],
      // [
      //   { text: '全部区域', value: '' },
      //   { text: '芙蓉区', value: '芙蓉区' },
      //   { text: '天心区', value: '天心区' },
      //   { text: '岳麓区', value: '岳麓区' },
      //   { text: '开福区', value: '开福区' },
      //   { text: '雨花区', value: '雨花区' },
      //   { text: '望城区', value: '望城区' },
      //   { text: '长沙县', value: '长沙县' },
      //   { text: '宁乡县', value: '宁乡县' },
      //   { text: '浏阳市', value: '浏阳市' }
      // ],
      sort: '1',
      sortList: [
        { text: '缺货由多到少', value: '1' },
        // { text: '断货由多到少', value: '2' },
        { text: '库存由少到多', value: '3' }
      ],
      tableList: []
    }
  },
  async mounted() {
    await this.getArea()
    await this.getInitData()
  },
  methods: {
    async getArea() {
      console.log('localStorage.getItem()====>', localStorage.getItem('cityCode'));
      const cityCode = localStorage.getItem('cityCode');
      await getAreaList({parentCode: cityCode}).then(res => {
        this.areaList = [{ text: '全部区域', value: '' }];
        res.data.data.forEach(item => {
          this.areaList.push({
            text: item.title,
            value: item.title,
          })
        })
      })
    },
    getInitData() {
      const params = {
        netName: this.netName,
        district: this.district,
        sort: this.sort
      }
      netNameStatistics(params).then( res => {
        if (res.data.code == 200) {
          this.tableList = res.data.data
        }
      })
    },
    onSearch() {
      
      this.getInitData()
    },
    toDetail(item) {
      this.$router.push({
        name: 'net_point_detail',
        query: {
          netId: item.netId,
          vmcCount: item.vmcCount,
          netName: item.netName,
          outStocks: item.outStocks,
          address: item.address
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.warp {
  ::v-deep(.van-dropdown-menu__item) {
    padding-left: 10px;
    justify-content: space-between;
  }

  .item {
    margin: 10px 12px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dcdee0;
    position: relative;

    .title {
      height: 24px;
      line-height: 24px;
      font-size: 16px;
    }
    .infos {
      height: 24px;
      line-height: 24px;
      .span {
        margin-right: 10px;
        color: #666;
      }
    }
    .arrow {
      position: absolute;
      right: 0;
      top: 40%;
      font-size: 16px;
      color: #999
    }
  }
}
</style>
